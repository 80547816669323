import { useEffect } from 'react'

import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import { useExperimentDispatch, useExperimentState } from 'experiments/hooks/useExperiment'
import { logExposure as logExperiment } from 'experiments/services/logExposure'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { getMParticleDeviceId, getMParticleSessionId } from 'mParticle/mParticleService'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useUser } from 'shared/hooks/useUser'

type IError = {
  message: string | null
}
/**
 *
 * @param experimentName Darwin experiment name
 * @param forceDisable If true, it forces the service to be disabled
 */
export const useLogExperiment = (
  experimentName: EXPERIMENT_NAME,
  forceDisable: boolean = false,
) => {
  const areExperimentsEnabled = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_EXPERIMENTS)
  const { currentUser, currentUserType } = useUser()
  const deviceId = getMParticleDeviceId()
  const userId = currentUser?.id
  const mainId = deviceId || userId
  const sessionId = getMParticleSessionId()

  const enableExperiment = areExperimentsEnabled && !forceDisable && mainId

  const experimentDispatch = useExperimentDispatch()
  const experimentState = useExperimentState()
  const currentExperiment = experimentState[experimentName]
  const groupAssigned = currentExperiment?.groupAssigned
  const hasLoggedExperiment = currentExperiment?.hasLoggedExperiment

  useEffect(() => {
    const logExposure = async (metaData: Record<string, unknown> = {}) => {
      try {
        await logExperiment(experimentName, mainId, deviceId, sessionId, {
          ...metaData,
          userId,
          currentUserType,
        })
        experimentDispatch({ type: 'logExperiment', payload: { experimentName } })
      } catch (error) {
        experimentDispatch({
          type: 'setError',
          payload: { experimentName, error: (error as IError)?.message as string | null },
        })
      }
    }

    if (enableExperiment && groupAssigned && !hasLoggedExperiment) {
      logExposure()
    }
  }, [
    enableExperiment,
    groupAssigned,
    hasLoggedExperiment,
    experimentName,
    mainId,
    deviceId,
    sessionId,
    userId,
    currentUserType,
    experimentDispatch,
  ])
}
