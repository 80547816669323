import { useEffect } from 'react'

import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import { useExperimentDispatch, useExperimentState } from 'experiments/hooks/useExperiment'
import { assign } from 'experiments/services/assign'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { getMParticleDeviceId } from 'mParticle/mParticleService'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useUser } from 'shared/hooks/useUser'

/**
 *
 * @param experimentName Darwin experiment name
 * @param forceDisable If true, it forces the service to be disabled
 */
export const useStartExperiment = (
  experimentName: EXPERIMENT_NAME,
  forceDisable: boolean = false,
) => {
  const areExperimentsEnabled = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_EXPERIMENTS)
  const { currentUser } = useUser()
  const deviceId = getMParticleDeviceId()
  const userId = currentUser?.id
  const mainId = deviceId || userId

  const enableExperiment = areExperimentsEnabled && !forceDisable && mainId

  const experimentDispatch = useExperimentDispatch()
  const experimentState = useExperimentState()

  useEffect(() => {
    const assignGroup = async () => {
      try {
        const data = await assign(experimentName, mainId)
        experimentDispatch({
          type: 'assignGroup',
          payload: { experimentName, groupAssigned: data?.name },
        })
      } catch (error) {
        experimentDispatch({
          type: 'setError',
          payload: { experimentName, error: error?.message },
        })
      }
    }

    if (enableExperiment && !experimentState[experimentName]) {
      experimentDispatch({
        type: 'startExperiment',
        payload: { experimentName },
      })
      assignGroup()
    }
  }, [enableExperiment, experimentDispatch, experimentName, experimentState, mainId])
}
